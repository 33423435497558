html {
  font-size: 62.5%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-button {
  padding: 15px 10px;
  background: #002d5d;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 40%;
  height: 15%;
  cursor: pointer;
}

.app-button--disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.app-button:hover {
  opacity: 0.7;
}

.app-button span {
  display: inline-block;
  margin-left: 1rem;
}

.container {
  display: flex;
  width: 80%;
  margin: 0px auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.container__logo {
  width: 50%;
}
.container__apps {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media only screen and (max-width: 960px) {
  .container {
    display: block;
  }

  .container__logo {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 5rem;
    align-items: center;
  }
  .container__apps {
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .container {
    width: 95%;
  }
}
